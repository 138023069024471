import * as React from "react"

const IndexPage = () => {
  return (
    <main>
      <h1>This site will be open</h1>
      <p>but it's secret right now</p>
    </main>
  )
}

export default IndexPage
